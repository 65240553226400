// Here you can add other styles
.noDataPBS20211022 {
  text-align: center;
}
.loadingImageHolder {
  text-align: center;
  height: 300px;
  width: 100%;
}
.loadingImage {
  //display: block;
  height: 100%;
  border: 0px;
  margin: 0px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
}
.fixedHead {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0px;
}
.filler {
  position: sticky;
  top: 0px;
  height: calc(1.25rem + 2px);
  width: 100%;
  background-color: white;
  z-index: 10;
}
.shiftup {
  margin-top: -2px;
}
.sticky {
  position: sticky;
  top: 1.25rem;
  background: white;
  z-index: 11;
}
